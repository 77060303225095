<template>
  <div>
    <h4>Broken Heart</h4>
    <div class="small mb-3">Installation with balloon</div>
    <p class="small">
      インターネット上には誹謗中傷が溢れ、その数は年々増加しています。こうした誹謗中傷は、人を死に追いやることもあります。<br />
      この作品は、日本における人種差別をテーマに、インターネットで行われる誹謗中書をリアルタイムで現実世界に反映させました。日本では、中国・韓国・北朝鮮に対する差別的なツイートが1分間にいくつもあります。作品は、誹謗中傷で傷つけられる心の中を表し、差別的なツイートに反応して、赤く光り、収縮が早くなります。<br />
      インターネット上で行われる誹謗中傷の先では、生身の人間が傷ついています。
    </p>
    <img src="@/assets/broken2.jpg" class="img-fluid" />
    <img src="@/assets/broken6.jpg" class="img-fluid" />
    <img src="@/assets/broken1.jpg" class="img-fluid" />
  </div>
</template>

<style>
img {
  margin-bottom: 10px;
}
</style>
